import DateFnsUtils from '@date-io/moment';
import { createStyles, makeStyles, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from 'formik-material-ui-pickers';
import React from "react";

type Props = {

}

export const CertificateForm = (props: Props) => {
    const classes = useStyles({});
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field
            name='title'
            component={TextField}
            label='Title'
            margin='normal'
            fullWidth
        />
        <Field
            name='units'
            component={TextField}
            label='Units'
            margin='normal'
            fullWidth
            type='number'
        />
        <Field
            name='subject_code'
            component={TextField}
            label='Subject Code (e.g. 200)'
            margin='normal'
            fullWidth
        />
        <Field
            name='speaker_name'
            component={TextField}
            label='Speaker Name (Optional)'
            margin='normal'
            fullWidth
        />
        <Field
            name='educational_method'
            component={TextField}
            label='Educational Method'
            margin='normal'
            fullWidth
            select>
            <MenuItem value='Lecture'>Lecture</MenuItem>
            <MenuItem value='Webinar'>Webinar</MenuItem>
            <MenuItem value='Participation'>Participation</MenuItem>
        </Field>
        <Field
            name='location'
            component={TextField}
            label='Location (Optional)'
            margin='normal'
            fullWidth
        />
        <Field
            name='course_date'
            component={DatePicker}
            label='Course Date (Optional)'
            margin='normal'
            fullWidth
        />

    </MuiPickersUtilsProvider>
}

const useStyles = makeStyles(theme => createStyles({

}))